<template>
  <div :class="isMobile ? 'app-nav-mobile' : 'app-nav'">
    <router-link to="/">
      <span :class="isMobile ? 'app-title-mobile' : 'app-title'">鑫创信息</span>
    </router-link>
    <img
      @click="handleClickImage('tc')"
      :src="navLanguageImage.tc"
      :class="isMobile ? 'nav-lang-image-mobile' : 'nav-lang-image'">
    <img
      @click="handleClickImage('en')"
      :src="navLanguageImage.en"
      :class="isMobile ? 'nav-lang-image-mobile' : 'nav-lang-image'">
    <img
      @click="handleClickImage('cn')"
      :src="navLanguageImage.cn"
      :class="isMobile ? 'nav-lang-image-mobile' : 'nav-lang-image'">
  </div>
</template>

<script>
export default {
  name: 'water-nav',

  props: {
  },

  created() {
  },

  data () {
    return {
      navLanguageImage: {
        en: require('@/assets/en/nav_en.png'),
        tc: require('@/assets/tc/nav_tc.png'),
        cn: require('@/assets/cn/nav_cn_checked.png')
      },
      // logoImage: require('@/assets/nav_logo.png'),
      minWindowWidth: 600,
      isMobile: false
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },

  methods:{
    onResize() {

      const windowWidth = window.innerWidth;

      if (windowWidth <= this.minWindowWidth) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }

    },

    handleClickImage(type) {
      // 初始化
      this.navLanguageImage = {
        en: require('@/assets/en/nav_en.png'),
        tc: require('@/assets/tc/nav_tc.png'),
        cn: require('@/assets/cn/nav_cn.png')
      };
      this.navLanguageImage[type] = require(`@/assets/${type}/nav_${type}_checked.png`);

      this.$emit('language-change', type);
    },
  }
}
</script>

<style>
.app-title {
  color: white;
  position: relative;
  left: 100px;
  top: 24px;
  width: 16.3%;
  height: auto;
  font-size: calc(0.1rem + 1.5vw);
}

.app-title-mobile {
  position: relative;
  left: 16px;
  top: 10px;

  width: 41.6%;
  height: auto;
  color: white;
  font-size: calc(0.1rem + 1.5vw);
}

.app-nav {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  height: 90px;
  background-color: #141414;
}

.app-nav-mobile {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  background-color: #141414;
}

.nav-lang-image {
  float: right;
  position: relative;
  top: 34px;
  width: 4%;
  height: auto;
  margin: 0 64px 0 0;
}

.nav-lang-image-mobile {
  float: right;
  position: relative;
  top: 24px;
  width: 8%;
  margin: 0 16px 0 0;
}

.nav-lang-image:hover {
  cursor: pointer;
}

.nav-lang-image-mobile:hover {
  cursor: pointer;
}
</style>