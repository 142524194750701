<template>
  <div class="water-body">
    <el-row><el-image :src="images[0]" :load="test"></el-image></el-row>

    <el-row>
      <el-image :src="images[1]"></el-image>
      <img
        class="logo-download"
        :src="logoDownload"
        :onclick="`javascript:location.href='${path.appDownload}'`">
    </el-row>

    <el-row><el-image :src="images[2]"></el-image></el-row>

    <el-row>
      <el-image :src="images[3]"></el-image>
      <router-link v-if="'en' === languageType" to="/watertracker-help-en">
        <img :style="{left:helpLfetNum}" class="logo-help" :src="logoHelp">
      </router-link>
      <router-link v-else-if="'cn' === languageType" to="/watertracker-help-cn">
        <img :style="{left:helpLfetNum}" class="logo-help" :src="logoHelp">
      </router-link>
      <router-link v-else to="/watertracker-help-tc">
        <img :style="{left:helpLfetNum}" class="logo-help" :src="logoHelp">
      </router-link>
    </el-row>

    <el-row class="box-about">
      <div class="box-about-inner">
        <div class="contact-us">
          <div>联系我们</div>
          <img
            class="logo-weibo"
            :src="logoIcon.weibo"
            :onclick="`javascript:location.href='${path.weibo}'`">
          <img
            class="logo-facebook"
            :src="logoIcon.facebook"
            :onclick="`javascript:location.href='${path.facebook}'`">
          <img
            class="logo-email"
            :src="logoIcon.email"
            :data-emial="path.email"
            :onclick="`javascript:location.href='mailto:${path.email}'`">
        </div>

        <div class="about-us">
          <div>关于</div>
          <router-link to="/terms">
            <img class="logo-terms" :src="logoIcon.terms">
          </router-link>
          <router-link to="/privacy">
            <img class="logo-privacy" :src="logoIcon.privacy">
          </router-link>
        </div>
      </div>
    </el-row>

    <Nav @language-change="handleClickImage"></Nav>
  </div>

  <el-backtop></el-backtop>
</template>

<script>
import Nav from '@/components/nav'
export default {
  name: 'water-home',
  
  components: {
    'Nav': Nav
  },

  props: {
    path: {
      type: Object
    }
  },

  data () {
    return {
      images: [
        require('@/assets/cn/page_0_cn.jpg'),
        require('@/assets/cn/page_1_cn.jpg'),
        require('@/assets/cn/page_2_cn.jpg'),
        require('@/assets/cn/page_3_cn.jpg')
      ],
      logoDownload: require('@/assets/cn/page_download_cn.png'),
      logoHelp: require('@/assets/cn/page_help_cn.png'),
      logoIcon: {
        weibo: require('@/assets/cn/page_weibo_cn.png'),
        email: require('@/assets/cn/page_email_cn.png'),
        facebook: require('@/assets/cn/page_facebook_cn.png'),
        terms: require('@/assets/cn/page_terms_cn.png'),
        privacy: require('@/assets/cn/page_privacy_cn.png')
      },

      helpLfetNum: '43.5%',
      navBarFixed: false,
      languageType: 'cn'
    }
  },

  mounted() {
    this.preload();
  },

  methods:{
    handleClickImage(type) {
      this.languageType = type;

      for (const key in this.images) {
        this.images[key] = require(`@/assets/${type}/page_${key}_${type}.jpg`);
      }

      if ('en' === type) {
        this.logoDownload = require('@/assets/en/page_download_en.png');

        this.helpLfetNum = '36%';
      } else {
        this.logoDownload = require('@/assets/cn/page_download_cn.png');

        this.helpLfetNum = '43.5%';
      }

      this.logoHelp = require(`@/assets/${type}/page_help_${type}.png`);

      for (const key in this.logoIcon) {
        this.logoIcon[key] = require(`@/assets/${type}/page_${key}_${type}.png`);
      }
    },

    preload() {
      const imgs = [
        require('@/assets/cn/page_0_cn.jpg'),
        require('@/assets/cn/page_1_cn.jpg'),
        require('@/assets/cn/page_2_cn.jpg'),
        require('@/assets/cn/page_3_cn.jpg'),

        require('@/assets/cn/page_0_cn_mobile.jpg'),
        require('@/assets/cn/page_1_cn_mobile.jpg'),
        require('@/assets/cn/page_2_cn_mobile.jpg'),
        require('@/assets/cn/page_3_cn_mobile.jpg'),

        require('@/assets/en/page_0_en_mobile.jpg'),
        require('@/assets/en/page_1_en_mobile.jpg'),
        require('@/assets/en/page_2_en_mobile.jpg'),
        require('@/assets/en/page_3_en_mobile.jpg'),
        require('@/assets/en/page_4_en_mobile.jpg'),

        require('@/assets/tc/page_0_tc.jpg'),
        require('@/assets/tc/page_1_tc.jpg'),
        require('@/assets/tc/page_2_tc.jpg'),
        require('@/assets/tc/page_3_tc.jpg'),
        require('@/assets/tc/page_4_tc.jpg'),

        require('@/assets/tc/page_0_tc_mobile.jpg'),
        require('@/assets/tc/page_1_tc_mobile.jpg'),
        require('@/assets/tc/page_2_tc_mobile.jpg'),
        require('@/assets/tc/page_3_tc_mobile.jpg'),
        require('@/assets/tc/page_4_tc_mobile.jpg')
      ];

      for (let img of imgs) {
        let image = new Image();
        image.src = img;
      }
    },
  }
}
</script>

<style>
.logo-download {
  position: absolute;
  top: 58.6%;
  left: 5.2%;
  width: 15.8%;
  height: 8.5%;
}

.logo-download:hover {
  cursor: pointer;
}

.logo-help {
  position: absolute;
  top: 87.3%;
  height: 7.3%;
}

.logo-help:hover {
  cursor: pointer;
}

.logo-weibo {
  top: 34.7%;
  left: 52%;
}

.logo-facebook {
  top: 46.1%;
  left: 52%;
}

.logo-email {
  top: 57.5%;
  left: 52%;
}

.logo-terms {
  top: 34.7%;
  left: 72.2%;
}

.logo-privacy {
  top: 46.1%;
  left: 72.2%;
}

.box-about {
  justify-content: center;

  width: 100%;
  margin: 120px 0 95px 0;
  max-height: 440px;
}

.box-about-inner {
  display: flex;
  justify-content: space-around;

  width: 50%;
  max-width: 800px;

  font-size: calc(0.1rem + 1.5vw);
  color: #525252;
}

.contact-us, .about-us {
  display: flex;
  flex-direction: column;
}

.contact-us div, .contact-us img {
  margin-bottom: 24px;
}

.about-us div, .about-us a {
  margin-bottom: 24px;
}

.contact-us img, .about-us img {
  cursor: pointer;
  width: 12vw;
}
</style>
